import { DEFAULT_LOCALE } from '@modules/app'
import {
  CartFragment,
  EnumValueInput,
  FieldDefinition,
  LineItem,
  RawCustomField,
  RawProductAttribute,
} from '@modules/commercetools'
import { EnumType, FieldType } from '@oriuminc/commercetools'
import { useMemo } from 'react'

type AttributeValue = { name: string; value: string }
type CustomFieldDictionary = Partial<Record<CustomField, AttributeValue>>
type VariantAttributeDictionary = Partial<
  Record<VariantAttribute, AttributeValue>
>
type UseLineItemAttributes = Record<
  string,
  {
    details: AttributeValue[]
    customFields: CustomFieldDictionary
    variantAttributes: VariantAttributeDictionary
  }
>

/**
 * These are some (but not all!) of the currently known custom fields and variant attributes.
 *
 * We need to add them here for them to show up in the Details:
 *
 * 1. Add them to their respective Enums, below.
 * 2. Add them to "customFieldsToIncludeInDetails"
 *    and/or "variantAttributesToIncludeInDetails", respectively.
 *
 * This is effectively a whitelist system.
 */
enum CustomField {
  ArtistID = 'artistID',
  ArtistName = 'artistName',
  DesignDescription = 'designDescription',
  DesignID = 'designID',
  DesignImageURL = 'designImageURL',
  DesignName = 'designName',
  DesignOwner = 'designOwner',
  Dpi = 'dpi',
  RepeatType = 'repeatType',
}

enum VariantAttribute {
  BeddingFinish = 'bedding-finish',
  BeddingProductName = 'bedding-product-name',
  BeddingStyle = 'bedding-style',
  CartDisclaimer = 'cart-disclaimer',
  Class = 'class',
  DiningProductName = 'dining-product-name',
  DiningSetSize = 'dining-set-size',
  DiningStyle = 'dining-style',
  Duration = 'duration',
  FabricColor = 'fabric-color',
  FabricContent = 'fabric-content',
  FabricName = 'fabric-name',
  FabricShortDescription = 'fabric-short-description',
  Format = 'format',
  LegacyPath = 'legacy-path',
  LivingAndDecorProductName = 'living-and-decor-product-name',
  LivingAndDecorStyle = 'living-and-decor-style',
  ProductStatus = 'product-status',
  SizeDisplay = 'size-display',
  SKU = 'sku',
  SubscriptionName = 'subscription-name',
  StockItemName = 'stock-item-name',
  TemplateName = 'template-name',
  VariantStatus = 'variant-status',
  WallartStyle = 'wallart-style',
  WallpaperName = 'wallpaper-name',
}

const customFieldsToIncludeInDetails = [] as const

const designOwnerFieldsToIncludeInDetails = [
  CustomField.Dpi,
  CustomField.RepeatType,
] as const

const variantAttributesToIncludeInDetails = [
  VariantAttribute.BeddingFinish,
  VariantAttribute.BeddingProductName,
  VariantAttribute.BeddingStyle,
  VariantAttribute.CartDisclaimer,
  VariantAttribute.DiningProductName,
  VariantAttribute.DiningStyle,
  VariantAttribute.DiningSetSize,
  VariantAttribute.Duration,
  VariantAttribute.FabricContent,
  VariantAttribute.FabricName,
  VariantAttribute.LivingAndDecorProductName,
  VariantAttribute.LivingAndDecorStyle,
  VariantAttribute.SizeDisplay,
  VariantAttribute.StockItemName,
  VariantAttribute.SKU,
  VariantAttribute.TemplateName,
  VariantAttribute.WallartStyle,
  VariantAttribute.WallpaperName,
] as const

const designOwnerIncludeInDetails = [
  ...customFieldsToIncludeInDetails,
  ...designOwnerFieldsToIncludeInDetails,
  ...variantAttributesToIncludeInDetails,
]

const includeInDetails = [
  ...customFieldsToIncludeInDetails,
  ...variantAttributesToIncludeInDetails,
]

function generateCustomFieldLabelDictionary(
  fieldDefinitions: FieldDefinition[] = []
) {
  const customFieldLabels: Partial<Record<CustomField, string>> = {}

  return fieldDefinitions.reduce(
    (dict, { label, name }) => ({
      ...dict,
      /**
       * FYI: "name" is the identifier (example: "artistID"),
       * hence why we're using that as the key.
       */
      [name]: label,
    }),
    customFieldLabels
  )
}

/**
 * The Commercetools type definition for "FieldDefinition" is technically wrong.
 * It should also include "EnumType" as a possible type.
 */
type FixedFieldDefinition = Omit<FieldDefinition, 'type'> & {
  type: FieldType | EnumType
}

const isEnumType = (object: unknown): object is EnumType => {
  const enumType = object as EnumType

  return enumType.name === 'LocalizedEnum'
}

function generateCustomFieldValueDictionary(
  fieldDefinitions: FixedFieldDefinition[] = []
) {
  const customFieldLabels: Partial<
    Record<CustomField, string | Record<string, string>>
  > = {}

  return fieldDefinitions.reduce((dict, { name, type }) => {
    const customFieldValue: Partial<Record<CustomField, EnumValueInput>> = {}
    const customValueDict = isEnumType(type)
      ? type.values.reduce(
          (valuesDict, { key, label }) => ({
            ...valuesDict,
            [key]: label,
          }),
          customFieldValue
        )
      : {}

    return {
      ...dict,
      [name]: customValueDict,
    }
  }, customFieldLabels)
}

function generateCustomFieldDictionary(
  customFieldsRaw: RawCustomField[] = [],
  customFieldLabels: ReturnType<typeof generateCustomFieldLabelDictionary>,
  customFieldValues: ReturnType<typeof generateCustomFieldValueDictionary>
) {
  const customFields: Partial<Record<CustomField, AttributeValue>> = {}

  return customFieldsRaw.reduce((customFieldDict, { name, value }) => {
    const customFieldValue = customFieldValues?.[name as CustomField]
    let localizedValue

    if (typeof customFieldValue === 'object' && customFieldValue[value]) {
      localizedValue = customFieldValue[value]
    } else {
      localizedValue = value
    }

    return {
      ...customFieldDict,
      /**
       * FYI: "name" is the identifier (example: "artistID"),
       * hence why we're using that as the key.
       */
      [name]: {
        value: localizedValue,
        name: customFieldLabels[name as CustomField],
      },
    }
  }, customFields)
}

function generateVariantAttributeDictionary(
  attributesRaw: RawProductAttribute[] = [],
  locale: string
) {
  const variantAttributeDictionary: Partial<
    Record<VariantAttribute, AttributeValue>
  > = {}

  return attributesRaw.reduce((variantAttributeDict, attribute) => {
    const { attributeDefinition, name, value } = attribute
    const { label, type } = attributeDefinition ?? {}
    const { name: attributeType } = type ?? {}

    // Attribute definition labels are locale aware, so no special logic is needed.
    const attributeName = label
    // Attribute values have various ways of being accessed, depending on the type.
    let attributeValue

    switch (attributeType) {
      case 'enum': // Non-localizable Enum
        attributeValue = value?.label
        break
      case 'lenum': // Localizable Enum
        attributeValue =
          value?.[locale]?.label ?? value?.[DEFAULT_LOCALE]?.label
        break
      case 'ltext': // Localizable Text
        attributeValue = value?.[locale] ?? value?.[DEFAULT_LOCALE]
        break
      default: // Non-localizable Primitive
        attributeValue = value
        break
    }

    return {
      ...variantAttributeDict,
      /**
       * FYI: "name" is the identifier (example: "item-code"),
       * hence why we're using that as the key.
       */
      [name]: {
        name: attributeName,
        value: attributeValue,
      },
    }
  }, variantAttributeDictionary)
}

export default function useLineItemAttributes(
  lineItems: CartFragment['lineItems'],
  locale: string
) {
  return useMemo<UseLineItemAttributes>(() => {
    const lineItemAttributes: UseLineItemAttributes = {}
    return lineItems.reduce((lineItemDict, lineItem) => {
      const { custom, id, variant } = lineItem as LineItem
      const { customFieldsRaw, type } = custom ?? {}
      const { fieldDefinitions } = type ?? {}
      /**
       * Custom fields have localized labels, but they're outside of "customFieldsRaw",
       * so we need to make a small dictionary of the labels so we can easily reference them
       * via each custom field's "name" property.
       */
      const customFieldLabels =
        generateCustomFieldLabelDictionary(fieldDefinitions)

      const customFieldValues =
        generateCustomFieldValueDictionary(fieldDefinitions)

      const { attributesRaw } = variant ?? {}

      /**
       * These dictionaries are meant for directly referencing an attribute
       * without needing to loop over the details (further below).
       */
      // #region Dictionaries
      const customFieldDictionary = generateCustomFieldDictionary(
        customFieldsRaw ?? [],
        customFieldLabels,
        customFieldValues
      )
      const variantAttributeDictionary = generateVariantAttributeDictionary(
        attributesRaw,
        locale
      )
      // #endregion Dictionaries

      /**
       * Details are an array of fields/attributes that we show on the CartProductCard.
       * Unlike the dictionaries, we do not include every single custom field / variant attribute here,
       * because things like "designID", "artistID", etc., are not very useful to a customer.
       */
      // #region Details
      const detailsDictionary = {
        ...customFieldDictionary,
        ...variantAttributeDictionary,
      }
      const isDesignOwner = customFieldDictionary?.designOwner?.value
      const designIncludeInDetails = isDesignOwner
        ? designOwnerIncludeInDetails
        : includeInDetails

      const details = Object.entries(detailsDictionary)
        .filter(([key, value]) =>
          designIncludeInDetails.includes(
            key as (typeof designIncludeInDetails)[number]
          )
        )
        .map(([key, value]) => value)
      // #endregion Details

      return {
        ...lineItemDict,
        [id]: {
          details,
          customFields: customFieldDictionary,
          variantAttributes: variantAttributeDictionary,
        },
      }
    }, lineItemAttributes)
  }, [lineItems])
}

export {
  CustomField,
  type CustomFieldDictionary,
  generateCustomFieldLabelDictionary,
  generateCustomFieldDictionary,
  generateVariantAttributeDictionary,
  VariantAttribute,
  type VariantAttributeDictionary,
}
